import type { IncomingMessage } from 'http';
import { Segment } from '@fixter/i18n';
import { get } from 'lodash';
import { DEFAULT_SEGMENT } from '$constants/segment';
import { getLocaleFromHostname } from '$util/domains';
import { getSegmentFromLocale } from './getSegmentFromLocale';

export const getSegmentFromServer = (req: IncomingMessage): Segment => {
  const host = get(req, 'headers.host', '');
  const locale = getLocaleFromHostname(host);
  const segment = getSegmentFromLocale(locale);
  return Object.values(Segment).find((val) => val === segment) || DEFAULT_SEGMENT;
};
