import { Segment } from '@fixter/i18n';
import JSCookies from 'js-cookie';
import { COOKIE_FIXTER_SEGMENT } from '$constants/cookie-names';
import { DEFAULT_SEGMENT } from '$constants/segment';

/**
 * Get segment from client cookies.
 *
 * @note if rendering based on this there will be a mismatch between SSR and CSR
 */
export const getSegmentFromClient = (): Segment => {
  const segment = JSCookies.get(COOKIE_FIXTER_SEGMENT);
  return Object.values(Segment).find((val) => val === segment) || DEFAULT_SEGMENT;
};
