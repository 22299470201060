import { Locale, Segment } from '@fixter/i18n';

/**
 * Functions to use instead:
 * @see `readSegment()`
 * @see `getSegmentFromDomain()`
 *
 * @deprecated Don't use this anymore because the relation between segments and locales has changed:
 *
 * 1 domain -> 1 segment -> 1 locale\
 * 1 locale -> many segments -> many domains
 *
 * @see https://github.com/fxtr/i18n/blob/256ec92d5d26c468bd24ab27d36cba7483208453/src/locale.ts#L31
 */
export const getSegmentFromLocale = (locale: Locale): Segment => {
  switch (locale) {
    case Locale.FR_FR:
      return Segment.Fixter_FR;
    case Locale.EN_GB:
      return Segment.Fixter_UK;
    default:
      return Segment.Fixter_UK;
  }
};
