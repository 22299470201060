import type { ComponentPropsWithoutRef } from 'react';
import React from 'react';
import classNames from 'classnames';
import { Img } from '@/components/atoms/Img';

import styles from './index.module.scss';

interface PoweredByFixterProps extends ComponentPropsWithoutRef<'div'> {
  readonly lightVersion?: boolean;
  readonly poweredByText?: string;
  readonly logoHeight?: number;
  readonly logoWidth?: number;
  readonly size?: 'small' | 'large';
}

const getLogoSize = (size: 'small' | 'large') => {
  if (size === 'large')
    return {
      width: 81,
      height: 23,
    };

  return {
    width: 59,
    height: 16,
  };
};

export function PoweredByFixter({
  className,
  lightVersion = true,
  poweredByText = 'Checkout powered by',
  size = 'small',
  ...props
}: PoweredByFixterProps): JSX.Element {
  const logoFilename = lightVersion
    ? 'logo-light-text-light-horizontal_fl29qz.svg'
    : 'logo-light-text-dark-horizontal-transparent-bg_dmaz5n.png';

  const logoSize = getLogoSize(size);

  return (
    <div className={classNames(PoweredByFixter.displayName, styles.component, className)} {...props}>
      <p
        className={classNames(
          styles.text,
          lightVersion ? styles.lightVersion : styles.darkVersion,
          styles[`text--${size}`]
        )}
      >
        {poweredByText}
      </p>
      <Img
        alt="fixter certified garage partner"
        provider="cloudinary"
        src={`/logo/${logoFilename}`}
        width={logoSize.width}
        height={logoSize.height}
      />
    </div>
  );
}
PoweredByFixter.displayName = 'PoweredByFixter';
