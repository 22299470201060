'use client';

import type { CSSProperties } from 'react';
import { useState, useCallback } from 'react';
import classNames from 'classnames';
import { isEmpty, omitBy } from 'lodash';
import { Locale } from '@fixter/i18n';
import { getActiveExperiments } from '$util/abTest';
import PostcodeAutocompleteV2 from '@/components/molecules/PostcodeAutocompleteV2';
import { Icon } from '@/components/atoms/Icon';
import type { ButtonBaseProps } from '@/components/atoms/Button';
import { Button } from '@/components/atoms/Button';
import { PoweredByFixter } from '@/components/atoms/PoweredByFixter';
import type { MandatoryProp } from '@/types/react';
import type { PoweredByFixterTranslations } from '@/components/atoms/PoweredByFixter/cms';
import type { VrmLookupFormTranslations } from './translations';
import {
  PostcodeValidationError,
  sanitizeString,
  validatePostcode,
  validateVrm,
  VrmValidationError,
} from './util';

import styles from './index.module.scss';

interface FormData {
  readonly vrm: string;
  readonly postcode: string;
}

export type VrmLookupProps = VrmLookupFormTranslations & {
  readonly locale: Locale;
  readonly initialVrm?: string;
  readonly initialPostcode?: string;
  readonly campaignId: MandatoryProp<string>;
  readonly sku: MandatoryProp<string>;
  readonly submitQueryParams?: string;
  readonly formAction?: string;
  readonly activeTab?: string;
  readonly targetBlank?: boolean;
  readonly disabled?: boolean;
  readonly ctaProps?: ButtonBaseProps;
  readonly className?: string;
  readonly garageId?: string;
  readonly isMembershipDomain?: boolean;
  readonly garageMembershipDomain?: string;
  readonly isMembershipGarage?: boolean;
  readonly buttonStylings?: CSSProperties;
  readonly poweredByFixterTranslations?: PoweredByFixterTranslations;
};

const checkIfMotrioDomain = () => {
  return ['fixter-nemo', 'motrio'].find((domain) => window.location.hostname.includes(domain));
};

/**
 * @todo consolidate all variations of VrmLookup into this component and rename it to VrmLookupForm.
 */
export function VrmLookupDumb({
  locale,
  textPostcode,
  textPostcodeRequired,
  textPostcodeInvalid,
  textRegistrationNumber,
  textRegistrationNumberRequired,
  textRegistrationNumberInvalid,
  textCTA,
  initialVrm = '',
  initialPostcode = '',
  sku,
  campaignId,
  submitQueryParams = '',
  formAction = '/vehicles/add',
  activeTab,
  targetBlank = false,
  disabled,
  ctaProps,
  className,
  garageId,
  buttonStylings,
  isMembershipDomain = false,
  isMembershipGarage = false,
  poweredByFixterTranslations,
  garageMembershipDomain,
}: VrmLookupProps): JSX.Element {
  const [formState, setFormState] = useState<FormData>({ vrm: initialVrm, postcode: initialPostcode });
  const [errors, setErrors] = useState({ vrm: '', postcode: '' });
  const [submitLoading, setSubmitLoading] = useState(false);
  const [focusedInput, setFocusedInput] = useState('');

  const validateFormData = useCallback(
    (formData: FormData) => {
      const { vrm, postcode } = formData;

      let vrmError = '';
      let postcodeError = '';

      try {
        validateVrm(vrm, locale);
      } catch (err) {
        if (err instanceof VrmValidationError) {
          switch (err.code) {
            case VrmValidationError.EMPTY:
              vrmError = textRegistrationNumberRequired;
              break;
            case VrmValidationError.INVALID:
              vrmError = textRegistrationNumberInvalid;
              break;
            default:
              break;
          }
        }
      }

      try {
        validatePostcode(postcode, locale);
      } catch (err) {
        if (err instanceof PostcodeValidationError) {
          switch (err.code) {
            case PostcodeValidationError.EMPTY:
              postcodeError = textPostcodeRequired;
              break;
            case PostcodeValidationError.INVALID:
              postcodeError = textPostcodeInvalid;
              break;
            default:
              break;
          }
        }
      }

      setErrors({ vrm: vrmError, postcode: postcodeError });
      return !vrmError && !postcodeError;
    },
    [
      locale,
      textPostcodeInvalid,
      textPostcodeRequired,
      textRegistrationNumberInvalid,
      textRegistrationNumberRequired,
    ]
  );

  const handleFocus = (inputName: string) => {
    setFocusedInput(inputName);
  };

  const handleBlur = () => {
    setFocusedInput('');
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const key = name as keyof typeof formState;
    setFormState((prevState) => ({ ...prevState, [key]: value }));
    if (errors[key]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }
  };

  const handlePostcodeSelect = (selectedPostcode: string) => {
    setFormState((prevState) => ({ ...prevState, postcode: selectedPostcode }));
    if (errors.postcode) {
      setErrors((prevErrors) => ({ ...prevErrors, postcode: '' }));
    }
  };

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      const isValid = validateFormData(formState);
      if (!isValid) return;

      const isGarageMembershipActive = isMembershipGarage || isMembershipDomain;
      const isMotrio = checkIfMotrioDomain();
      const passMembershipFlag = isGarageMembershipActive.toString();
      const passGarageId = isGarageMembershipActive || isMotrio ? garageId : undefined;

      setSubmitLoading(true);
      const queryParams = new URLSearchParams(
        omitBy(
          {
            vrm: sanitizeString(formState.vrm),
            postcode: sanitizeString(formState.postcode),
            sku,
            campaignId,
            activeTab,
            activeExperiments: getActiveExperiments(),
            garageId: passGarageId,
            isGarageMembership: isGarageMembershipActive ? passMembershipFlag : undefined,
            garageMembershipDomain,
          },
          isEmpty
        ) as Record<string, string>
      ).toString();

      const formSubmitPath = `${formAction}?${queryParams}&${submitQueryParams}`;

      if (targetBlank) {
        window.open(formSubmitPath, '_blank');
      } else {
        window.location.href = formSubmitPath;
      }
    },
    [
      validateFormData,
      formState,
      isMembershipGarage,
      isMembershipDomain,
      garageId,
      sku,
      campaignId,
      activeTab,
      formAction,
      submitQueryParams,
      targetBlank,
    ]
  );

  return (
    <form onSubmit={handleSubmit} className={classNames(VrmLookupDumb.name, styles.form, className)}>
      <PostcodeAutocompleteV2
        translations={{ textPostcode }}
        onPostcodeSelect={handlePostcodeSelect}
        onChange={handleChange}
        errorMessage={errors.postcode}
        useAutocomplete={locale === Locale.EN_GB}
        className={styles.postcodeAutocomplete}
      />
      <div className={styles.inputContainer}>
        <Icon
          id="travel/car-01"
          legacy={false}
          className={classNames(styles.icon, { [styles.iconFocused]: focusedInput === 'vrm' })}
        />
        <input
          type="text"
          name="vrm"
          value={formState.vrm}
          onChange={handleChange}
          className={classNames(styles.input, errors.vrm && 'inputError')}
          placeholder={textRegistrationNumber}
          onFocus={() => handleFocus('vrm')}
          onBlur={handleBlur}
          data-testid="input-vrm"
        />
        {errors.vrm && (
          <div className={classNames('errorMessage', styles.errorMessage, { [styles.visible]: errors.vrm })}>
            {errors.vrm}
          </div>
        )}
      </div>
      <Button
        className={styles.button}
        size="L"
        type="submit"
        fullWidth
        loading={submitLoading}
        disabled={disabled}
        style={buttonStylings}
        {...ctaProps}
      >
        {textCTA}
      </Button>
      {isMembershipDomain && (
        <PoweredByFixter
          className={styles.PoweredBy}
          lightVersion={false}
          poweredByText={poweredByFixterTranslations?.checkoutPoweredByText}
        />
      )}
    </form>
  );
}
